import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import images from "../../themes/appImage";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { Radio } from 'antd';
import { Link, useNavigate } from "react-router-dom";


const ChangeLanguage = (props) => {



  const navigate = useNavigate();
  const [value, setValue] = useState("");


  const handleChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    document.title = 'Super Note';;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["usermanagement"]}
        pathName={[
          <img src={images.home} />,
          appconstant.Settings, appconstant.changeLanguage,
        ]}
      />

      <div className="outer-div">
        <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 ">
          <h6 class="text-white text-capitalize ps-3">
            {appconstant.changeLanguage}
          </h6>
        </div>
        <div className="main-div-1 dashboard ">
          <div className="datacenter radio-sec">
            <div className="image-fle">

            </div>
            <div className=" language-text" >
              <p><Radio.Group style={{ display: 'flex', justifyContent: 'space-around' }} options={['English', 'Hebrew']} onChange={handleChange} value={value} /></p>
            </div>
          </div>
        </div>
        <div className="up-cent">
          <Link to="/login">
            <button type="submit" className="button-list change-pass-btn">{appconstant.buttonupate}</button>
          </Link>
        </div>

      </div>
    </div>
  );
};
export default ChangeLanguage;
