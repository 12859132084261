import React, { useState, useEffect } from "react";
import { Button, Layout, Menu } from "antd";
import images from "../../themes/appImage";
import { DatePicker, Space } from "antd";
import { Checkbox } from "antd";
import { Pagination } from "antd";

import { BrowserRouter as Router, Link } from "react-router-dom";
import HeaderAdmin from "../../layout/header";
import { Select } from "antd";


import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal, Image } from "antd";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import Textfiled from "../../common";

import { adsList, deleteAdd, AddAd, editAd } from '../../Store/Action/ads'
import { categoryList } from '../../Store/Action/favourite'
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { message, Upload } from 'antd';
import moment from "moment";

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import 'antd/dist/antd.css';
// import { setOptions } from "react-chartjs-2/dist/utils";

const { Option } = Select;

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const onChange1 = (e) => {
  console.log(`checked = ${e.target.checked}`);
};


const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const AdsManagement = () => {
  // document.body.style.overflow = "hidden";
  const [modal1Open, setModal1Open] = useState(false);
  const [modal2Open, setModal2Open] = useState(false);
  const [scrolling, setScrolling] = useState(true)
  const [dropdownOpen, setdropdownOpen] = useState(false);
  const [descCount, setdescCount] = useState(0);
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [addImage, setaddImge] = useState('')
  const [editImage, seteditImge] = useState('')
  const [previousImage, setPreviousImage] = useState('')
  const [removeImage, setRemoveImage] = useState(false)
  const [visible, setVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState('');
  const [showArrow, setshowArrow] = useState(true)
  const imagebaseUrl = appconstant.reduxConstant.IMAGE_URL


  const handleClose = () => {
    setVisible(false)
  }

  useEffect(() => {
    if (visible) {
      const element = document.getElementsByClassName("ant-image-preview-operations-operation")
      if (element[0]) {
        element[0].addEventListener("click", handleClose)
      } else {
        console.log("Element not found", element)
      }
    } else {
      const element = document.getElementsByClassName("ant-image-preview-operations-operation")
      if (element[0]) {
        element[0].removeEventListener("click", handleClose)
      } else {
        console.log("Element not found", element)
      }
    }
    return () => {
      const element = document.getElementsByClassName("ant-image-preview-operations-operation")
      if (element[0]) {
        element[0].removeEventListener("click", handleClose)
      } else {
        console.log("Element not found", element)
      }

    }
  }, [visible])
  const handleChange = (value) => {
    console.log('value', value)
    setImageUrl(value);
  };

  const dispatch = useDispatch()

  const adsListData = useSelector((state) => state.AdsReducer.adsList)
  const pagination = useSelector((state) => state.AdsReducer.adsListPagination)

  let categories = useSelector((state) => state.FavouriteReducer.favouriteList)

  //let [categories, setCategory] = useState(category)
  const [searchCategory, setSearchCategory] = useState('')
  const [options, setoptions] = useState([])

  useEffect(() => {
    setoptions(categories)
  }, [categories])


  useEffect(() => {
    if (pagination?.currentPage === 1) {
      setOffset(0)
      setLimit(10)
    }
  }, [adsListData])

  useEffect(() => {
    console.log('modal1Open', modal1Open, modal2Open)
    if (modal1Open || modal2Open) {
      document.body.style.overflow = "hidden !important"
    }
    else {
      document.body.style.overflow = "scroll !important"
    }

  }, [modal1Open, modal2Open])

  const validateUrlRegex = /(https: \/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?\/[a-zA-Z0-9]{2,}|((https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z]{2,}(\.[a-zA-Z]{2,})(\.[a-zA-Z]{2,})?)|(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})?/g;


  const validationSchema = Yup.object().shape({
    category: Yup.string()
      .optional()
      .nullable(true),

    description: Yup.string()
      .required("Please enter description.")
      .min(3, 'Description must conatain at least 3 characters.')
      .max(300),

    link: Yup.string()
      .required("Please enter link.")
      .matches(validateUrlRegex, "Please enter valid link.")
      .min(3, 'Description must conatain at least 3 characters.')
      .max(300)
  });


  const [addInitialValues, setaddIntialValues] = useState({
    category: "",
    description: "",
    startTime: moment(new Date()),
    endTime: moment(new Date()),
    noLimit: false,
    link: ""

  })
  const formik = useFormik({
    initialValues: addInitialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values.noLimit) {
        if (values.startTime) values.startDate = values.startTime
        if (values.endTime) values.endDate = values.endTime
      }
      else {
        values.startDate = null
        values.endDate = null
      }
      const formData = new FormData()
      if (addImage) formData.append('media', addImage)
      formData.append('description', values.description)
      formData.append('noLimit', values.noLimit)
      values.category && formData.append('category', values.category)
      formData.append('link', values.link)
      if (values.startTime) formData.append('startDate', values.startTime)
      if (values.endTime) formData.append('endDate', values.endTime)
      console.log('adddd', addImage)
      dispatch(AddAd(formData))
      setModal1Open(false)

    },
    validationSchema
  })

  const [editInitialValues, seteditIntialValues] = useState({
    ads: "",
    category: "",
    categoryName: "",
    description: "",
    startTime: "",
    endTime: "",
    noLimit: "",
    link: ""
  })

  const editformik = useFormik({
    initialValues: editInitialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!values.noLimit) {
        if (values.startTime) values.startDate = values.startTime
        if (values.endTime) values.endDate = values.endTime
      }
      else {
        values.startDate = null
        values.endDate = null
      }

      const formData = new FormData()
      if (editImage) {
        formData.append('media', editImage)
      }
      else {
        if (removeImage && !previousImage) formData.append('removeImage', removeImage)
      }
      formData.append('ads', values.ads)
      formData.append('description', values.description)
      formData.append('noLimit', values.noLimit)
      values.category && formData.append('category', values.category)
      formData.append('link', values.link)

      if (values.startTime) formData.append('startDate', values.startDate)
      if (values.endTime) formData.append('endDate', values.endDate)

      dispatch(editAd(formData))
      setModal2Open(false)

    },
    validationSchema
  })


  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const csvRecords = []
    csvData.forEach(e => {
      csvRecords.push({ Category: e.category, Description: e.description, "Time Range": e.endTime })
    });
    const ws = XLSX.utils.json_to_sheet(csvRecords, { header: ['Category', 'Description', 'Time Range'] });
    //const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const colWidths = [];
    ws['!cols'] = [];

    // Iterate over each row
    XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach(row => {
      // Iterate over each cell in the row
      row.forEach((cell, colIndex) => {
        // Check if the column width needs to be adjusted
        colWidths[colIndex] = Math.max(colWidths[colIndex] || 0, cell.toString().length);
      });
    });

    // Set the column widths in the worksheet
    colWidths.forEach((width, colIndex) => {
      ws['!cols'][colIndex] = { width: width + 2 }; // You can adjust the constant value as needed
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const disableModel = (record) => {
    console.log('record', record)
    Modal.confirm({
      icon: <img className="moale-image" src={images.alert} />,
      content: (
        <div className="content-model">
          {" "}
          <p> Are you sure,</p> <p>You want to delete this Ad? </p>
        </div>
      ),
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        dispatch(deleteAdd({ ads: record }))
      },
      onCancel() { },
      wrapClassName: "new-button",
    });
  };

  useEffect(() => {
    dispatch(categoryList({ offset: 0, limit: 100 }))
    dispatch(adsList({ offset, limit }))
  }, [])


  // useEffect(() => {
  //   dispatch(categoryList({ search: searchCategory }))
  // }, [searchCategory])






  // const dataSource = [
  //   {
  //     key: "1",
  //     image: <img src={images.notes} />,
  //     category: "Shopping",
  //     description: "Lorem ipsum is a dummy text...",
  //     time: "24 Oct to 28 Oct 2023",
  //   },
  //   {
  //     key: "1",
  //     image: <img src={images.notes} />,
  //     category: "Shopping",
  //     description: "Lorem ipsum is a dummy text...",
  //     time: "24 Oct to 28 Oct 2023",
  //   },
  //   {
  //     key: "1",
  //     image: <img src={images.notes} />,
  //     category: "Shopping",
  //     description: "Lorem ipsum is a dummy text...",
  //     time: "24 Oct to 28 Oct 2023",
  //   },
  //   {
  //     key: "1",
  //     image: <img src={images.notes} />,
  //     category: "Shopping",
  //     description: "Lorem ipsum is a dummy text...",
  //     time: "24 Oct to 28 Oct 2023",
  //   },
  //   {
  //     key: "1",
  //     image: <img src={images.notes} />,
  //     category: "Shopping",
  //     description: "Lorem ipsum is a dummy text...",
  //     time: "24 Oct to 28 Oct 2023",
  //   },
  // ];

  const dataSource = adsListData.map((e) => {
    return {
      _id: e._id,
      image: e.image ? e.image : "N/A",
      category: e.category,
      description: e.description,
      startTime: e.startDate ? moment(e.startDate).format('YYYY-MM-DD') : "N/A",
      endTime: e.endDate ? moment(e.endDate).format('YYYY-MM-DD') : "N/A",
      categoryId: e.categoryId,
      link: e.link ? e.link : ""
    }
  })
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => offset + index + 1,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      sorter: false,
      render: (value, item, index) => {
        // console.log('checkItem', item)
        return item.image != "N/A" ? <img src={`${imagebaseUrl}/${item.image}`} /> : "N/A"
      }
    },

    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: true,
      render: (value, item, index) => {
        return item.category || "N/A"
      }
    },

    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      sorter: true,
    },

    {
      title: "Time Range",
      dataIndex: "endTime",
      key: "endTime",
      sorter: false,
    },

    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div>
            <button
              onClick={() => {
                console.log('item?.description', item?.description)

                seteditIntialValues({
                  ads: item?._id,
                  description: item?.description,
                  category: item?.categoryId,
                  categoryName: item?.category,
                  startTime: item?.startTime != "N/A" ? item?.startTime : "",
                  endTime: item?.endTime != "N/A" ? item?.endTime : "",
                  noLimit: item.startTime === "N/A" ? true : false,
                  link: item?.link ? item?.link : false
                })
                setdescCount(item?.description?.length)
                setPreviousImage(item?.image != "N/A" ? item?.image : "")
                seteditImge('')
                setModal2Open(true);
              }}
            //type="submit"
            >
              {appconstant.edit}
            </button>

            <button
              className="disable-model"
              type="submit"
              onClick={() => disableModel(item?._id)}
            >
              {appconstant.Delete}
            </button>
          </div>
        );
      },
    },
  ];



  useEffect(() => {
    document.title = 'Super Note';
    return () => Modal.destroyAll()
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["usermanagement"]}
        pathName={[<img src={images.home} />, appconstant.adsManagement]}
      />
      {/* Add model */}

      <form onSubmit={formik.handleSubmit}>
        <div className="view-offer-modal ">
          <Modal
            title="Add Ads"
            className="cross-model add-modal "
            centered
            visible={modal1Open}

            onCancel={() => {
              setdropdownOpen(false)
              // formik.setFieldValue('description', "")
              // formik.setFieldValue('startTime', moment(new Date()))
              // formik.setFieldValue('endTime', moment(new Date()))
              // formik.setFieldValue("category", null)
              // formik.setFieldValue("noLimit", false)
              setModal1Open(false)
            }}
            footer={[
              <button className="can-btn" onClick={() => { setModal1Open(false) }}>
                Cancel
              </button>,
              <button
                className="add-fav modal-add "
                onClick={formik.submitForm}
              >
                Add
              </button>,
            ]}
          >
            <div className="modal-offer ">
              <div className="add-images">
                <input type="file" id="imagePicker1" accept="image/*" style={{ display: "none" }} onChange={(e) => {
                  console.log('file', e.target.files[0])
                  setaddImge(e.target.files[0])
                  console.log('url', URL.createObjectURL(e.target.files[0]))

                }} />
                <label htmlFor="imagePicker1"><img src={addImage ? URL.createObjectURL(addImage) : images.addImage} /></label>

                {/* <Upload
                  name="avatar"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a218"
                  // beforeUpload={beforeUpload}
                  onChange={handleChange}
                >
                  {console.log('imageUrl')}
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      Upload
                    </div>
                  )}
                </Upload> */}


                {/* <img src={images.addImage}  /> */}
              </div>

              <div className="input-fields-dis ">
                <div className="slect-label"></div>
                <Space wrap>
                  <Select
                    showSearch
                    open={dropdownOpen}
                    placeholder={appconstant.category}
                    showArrow={showArrow}
                    onSearch={(e) => {
                      if (e.length > 57) setshowArrow(false)
                      else setshowArrow(true)
                    }}
                    optionFilterProp="children"
                    className="custom-select"
                    onChange={(e) => {
                      formik.setFieldValue("category", e)

                    }}
                    value={formik.values.category}
                    onMouseLeave={
                      (e) => {
                        setdropdownOpen(false)
                        // setSearchCategory('')
                      }
                    }
                    onSelect={() => setdropdownOpen(false)}

                    onClick={() => {
                      !dropdownOpen && setdropdownOpen(true)
                    }}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }

                  // options={options.map((e) => {
                  //   return {
                  //     value: e._id,
                  //     label: e.categoryName
                  //   }
                  // })}
                  // style={{ cursor: 'pointer' }}
                  >
                    {options.map((option) => (
                      <Option key={option.categoryName} value={option._id}>
                        {option.categoryName}
                      </Option>
                    ))}
                  </Select>
                </Space>
              </div>
              {formik.errors.category ? <><div className="select">{formik.errors.category}</div></> : null}

              <div className="input-offer-modal">
                <textarea
                  id="w3review"
                  name="description"
                  rows="4"
                  cols="50"
                  placeholder="Description"
                  value={formik.values.description}
                  maxLength={300}
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      formik.setFieldValue("description", value)
                      console.log('desc', value.length)
                      setdescCount(value.length)
                    }
                  }
                />
                {formik.errors.description ? <><div className="login-validation">{formik.errors.description}</div></> : null}
                <p className="text-veli">{`${descCount} / 300`}</p>
              </div>

              <div className="modal-offer ">
                <div className="input-offer-modal" style={{ marginBottom: "8px" }}>
                  <Textfiled
                    placeholder={"Add Link"}
                    autocomplete={"off"}
                    name="link"
                    value={formik.values.link}
                    maxLength={1000}
                    onChange={
                      (e) => {
                        const value = (e.target.value).trimStart()
                        formik.setFieldValue("link", value)
                      }
                    }
                  />
                  {formik.errors.link ? <><div className="login-validation">{formik.errors.link}</div></> : null}
                </div>

              </div>

              <div className="date-picker">
                {!formik.values.noLimit ? (<label>Time Range</label>) : null}
                {!formik.values.noLimit ? (<div className="select-date">
                  <Space direction="vertical">
                    <DatePicker
                      allowClear={false}
                      name="startTime"
                      value={formik.values.startTime}
                      disabledDate={(current) => current.isBefore(moment())}
                      onChange={(e) => {
                        if (e) {
                          formik.setFieldValue("startTime", moment(e._d))
                        }
                      }}
                      placeholder="Start Date"
                      format="DD MMM YYYY"
                      placement="topLeft"
                    />
                  </Space>

                  <Space direction="vertical">
                    <DatePicker
                      allowClear={false}
                      name="endTime"
                      value={formik.values.endTime}
                      disabledDate={(current) => current.isBefore(moment())}
                      onChange={(e) => {
                        if (e) {
                          formik.setFieldValue("endTime", moment(e._d))
                        }
                      }}
                      placeholder="End Date"
                      format="DD MMM YYYY"
                      placement="topLeft"
                    />
                  </Space>
                </div>) : null}

                <div className="check-box add-kra">
                  <Checkbox
                    onChange={(e) => {
                      const checked = e.target.checked
                      formik.setFieldValue("noLimit", checked)
                      if (checked) {
                        formik.setFieldValue("startTime", null)
                        formik.setFieldValue("endTime", null)
                      }
                      else {
                        formik.setFieldValue("startTime", moment(new Date()))
                        formik.setFieldValue("endTime", moment(new Date()))
                      }
                    }}
                    checked={formik.values.noLimit}
                  >No limit</Checkbox>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </form>
      {/* end */}
      <div
        className="site-layout-background"
        style={{ minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 ">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.adsManagement}
            </h6>
          </div>

          <div className="system-line">
            <div className="export-top-btn">
              <button
                onClick={() => {
                  // document.body.style.overflow = "hidden"
                  // window.location.reload();

                  // formik.setFieldValue('description', "")
                  // formik.setFieldValue('startTime', moment(new Date()))
                  // formik.setFieldValue('endTime', moment(new Date()))
                  // formik.setFieldValue("category", null)
                  // formik.setFieldValue("noLimit", false)
                  // setdescCount(item?.description?.length)
                  setaddIntialValues({
                    description: "",
                    category: null,
                    noLimit: false,
                    startTime: moment(new Date()),
                    endTime: moment(new Date()),
                    link: ""
                  })
                  setaddImge('')

                  setModal1Open(true);

                  setdescCount(0)
                }}
                type="submit"
                className="button-list"
              >
                {appconstant.add}
              </button>

              <button className="button-list" onClick={() => exportToCSV(dataSource, Date.now())}>
                {appconstant.Export}
              </button>
            </div>

            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                const value = (e.target.value).trimStart()
                setSearch(value)
                dispatch(adsList({ search: value }))
              }}
            />
          </div>
          <div className="tableContainer">
            <Table
              dataSource={dataSource}
              columns={columns}
              showSorterTooltip={false}
              onChange={(aaa, aa, sort) => {
                const { field, order } = sort
                let sortData = null
                if (field) sortData = field
                let orderStatus = null
                if (order === "ascend") orderStatus = 1
                else if (order === "descend") orderStatus = -1
                dispatch(adsList({ sort: sortData, order: orderStatus, offset: search ? 0 : offset, limit: search ? 10 : limit, search }))

              }}
            />
          </div>
          <div className="bott-pagination">
            <Pagination current={pagination?.currentPage} defaultCurrent={1} total={pagination?.totalCount}
              showSizeChanger={false}
              onChange={(pageNumber) => {
                console.log('cvvvvvvv', pageNumber)
                const pageSize = 10
                setOffset(((pageNumber - 1) * pageSize))
                setLimit(pageSize)
                dispatch(adsList({ offset: ((pageNumber - 1) * pageSize), limit: pageSize }))
              }}

            />
          </div>
        </div>
      </div>

      {/* Edit model */}
      <form onSubmit={editformik.handleSubmit}>
        <div className="view-offer-modal ">
          <Modal
            title="Edit Ads"
            className="cross-model offer-person-modal "
            centered
            visible={modal2Open}
            onCancel={() => {
              seteditIntialValues({
                ads: "",
                description: "",
                category: "",
                categoryName: "",
                startTime: "",
                endTime: "",
                noLimit: "",
                link: ""
              })
              setModal2Open(false)
            }}
            bodyStyle={{ overflow: 'hidden' }}
            footer={[
              <button className="can-btn" onClick={() => {
                seteditIntialValues({
                  ads: "",
                  description: "",
                  category: "",
                  categoryName: "",
                  startTime: "",
                  endTime: "",
                  noLimit: ""
                })
                setModal2Open(false)
              }}>
                Cancel
              </button>,
              <button
                className="add-fav modal-add"
                onClick={editformik.submitForm}
              >
                Update
              </button>,
            ]}
          >
            <div className="modal-offer ">
              <div className="add-images">

                <input type="file" id="imagePicker2" accept="image/*" style={{ display: "none" }} onChange={(e) => {
                  console.log('file', e.target.files[0])
                  setPreviousImage('')
                  seteditImge(e.target.files[0])

                }} />
                <label htmlFor="imagePicker2"><img style={{ height: "70px", "margin-left": (!previousImage && !editImage) ? "0px" : "50px", borderRadius: "45%" }} src={!previousImage ? editImage ? URL.createObjectURL(editImage) : images.addImage : `${imagebaseUrl}/${previousImage}`} /></label>
                {
                  (previousImage || editImage) ? (<img onClick={() => {
                    setPreviousImage('')
                    seteditImge('')
                    setRemoveImage(true)
                  }} src={images.cross} style={{ height: "23px", marginTop: "51px", paddingRight: "31px" }}></img>) : null}

                <Image
                  style={{ display: "none" }}
                  src={!previousImage ? editImage ? URL.createObjectURL(editImage) : images.addImage : `${imagebaseUrl}/${previousImage}`}
                  preview={{
                    visible,
                    src: !previousImage ? editImage ? URL.createObjectURL(editImage) : images.addImage : `${imagebaseUrl}/${previousImage}`,

                  }}


                />

              </div>

              {previousImage || editImage ? (<img style={{ "height": "50px", "margin-left": "201px", "margin-top": "-27px", cursor: "pointer" }} src={images.preview} onClick={() => setVisible(true)}  ></img>) : null}

              <div className="input-fields-dis ">
                <div className="slect-label"></div>
                <Space wrap>
                  <Select
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    optionFilterProp="children"
                    showSearch
                    searchProps={{ maxLength: 10 }}
                    showArrow={showArrow}
                    onSearch={(e) => {
                      if (e.length > 57) setshowArrow(false)
                      else setshowArrow(true)
                    }}
                    open={dropdownOpen}
                    placeholder={appconstant.category}
                    className="custom-select"
                    name="category"
                    value={editformik.values.categoryName}
                    onChange={(e) => {
                      console.log('val1111', e)
                      editformik.setFieldValue("category", e)
                      editformik.setFieldValue("categoryName", e)
                      setdropdownOpen(false)
                    }}
                    defaultValue={[]}
                    onMouseLeave={
                      (e) => {
                        setdropdownOpen(false)
                      }
                    }
                    onSelect={() => setdropdownOpen(false)}

                    onClick={() => {
                      !dropdownOpen && setdropdownOpen(true)
                    }}
                  >
                    {options.map((option) => (
                      <Option key={option.categoryName} value={option._id}>
                        {option.categoryName}
                      </Option>
                    ))}
                  </Select>

                </Space>
              </div>
              {editformik.errors.category ? <><div className="select">{editformik.errors.category}</div></> : null}

              <div className="input-offer-modal">
                <textarea
                  id="w3review"
                  name="description"
                  value={editformik.values.description}
                  maxLength={300}
                  rows="4"
                  cols="50"
                  placeholder="Description"
                  onChange={
                    (e) => {
                      const value = (e.target.value).trimStart()
                      editformik.setFieldValue("description", value)
                      setdescCount(value.length)
                    }
                  }
                />
                {editformik.errors.description ? <><div className="login-validation">{editformik.errors.description}</div></> : null}
                <p className="text-veli">{`${descCount}/300`}</p>
              </div>


              <div className="modal-offer ">
                <div className="input-offer-modal" style={{ marginBottom: "8px" }}>
                  <Textfiled
                    placeholder={"Add Link"}
                    autocomplete={"off"}
                    name="link"
                    value={editformik.values.link}
                    maxLength={1000}
                    onChange={
                      (e) => {
                        const value = (e.target.value).trimStart()
                        editformik.setFieldValue("link", value)
                      }
                    }
                  />
                  {editformik.errors.link ? <><div className="login-validation">{editformik.errors.link}</div></> : null}
                </div>

              </div>


              <div className="date-picker">
                {!editformik.values.noLimit ? (<label>Time Range</label>) : null}
                {!editformik.values.noLimit ?
                  (<div className="select-date">
                    <Space direction="vertical">
                      <DatePicker
                        allowClear={false}
                        onChange={(e) => {
                          if (e) {
                            editformik.setFieldValue("startTime", moment(e._d))
                          }
                        }}
                        value={moment(editformik.values.startTime ? editformik.values.startTime : undefined)}
                        disabledDate={(current) => current.isBefore(moment())}
                        placeholder="Start Date"
                        format="DD MMM YYYY"
                        placement="topLeft"
                      />
                    </Space>

                    <Space direction="vertical">
                      <DatePicker
                        allowClear={false}
                        onChange={(e) => {
                          if (e) {
                            editformik.setFieldValue("endTime", moment(e._d))
                          }
                        }}
                        value={moment(editformik.values.endTime ? editformik.values.endTime : undefined)}
                        disabledDate={(current) => current.isBefore(moment())}
                        placeholder="End Date"
                        format="DD MMM YYYY"
                        placement="topLeft"
                      />
                    </Space>
                  </div>
                  ) : null}
                <div className="check-box add-kra">
                  <Checkbox
                    onChange={(e) => {
                      const checked = e.target.checked

                      editformik.setFieldValue("noLimit", checked)
                      if (checked) {
                        editformik.setFieldValue("startTime", null)
                        editformik.setFieldValue("endTime", null)
                      }
                      else {
                        editformik.setFieldValue("startTime", moment(new Date()))
                        editformik.setFieldValue("endTime", moment(new Date()))
                      }
                    }}
                    checked={editformik.values.noLimit}
                  >No limit</Checkbox>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </form >
      {/* end */}
    </div >
  );
};
export default AdsManagement;
