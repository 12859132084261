import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

//** favoourite add **/

export const addFavoriteCategory = (data, navigate) => ({ type: reduxConstant.ADD_FAVOURITE_LOAD, data, navigate });

export const categoryList = (data) => ({ type: reduxConstant.CATEGORY_LIST_LOAD, data });

export const disableCategory = (data) => ({ type: reduxConstant.CATEGORY_DISABLE_LOAD, data });

export const editCategory = (data, navigate) => ({ type: reduxConstant.CATEGORY_EDIT_LOAD, data, navigate });






