//** common function for the navigation and the dispatch function **/

let navigater;
let dispatcher;

//** navigation function **/

export const naviagetFn = (navigateFn) => {
  navigater = navigateFn;
};
export const navigate = (data, state) => {
  return navigater(data, state);
};

//** dispatch function **/

export const dispatchFn = (dispatch) => {
  dispatcher = dispatch;
};
export const dispatch = (data) => {
  return dispatcher(data);
};
