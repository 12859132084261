import React, { useState, useEffect } from "react";
import { Layout, Menu, Pagination } from "antd";
import images from "../../themes/appImage";
import { DatePicker, Space } from "antd";

import { BrowserRouter as Router, Link } from "react-router-dom";
import HeaderAdmin from "../../layout/header";

import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";
import { Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { inventoryList } from "../../Store/Action/inventory"

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { toast } from "react-toastify";



const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const onChange1 = (e) => {
  console.log(`checked = ${e.target.checked}`);
};

const InventoryManagement = () => {
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState(0)
  const [order, setOrder] = useState(10)

  const dispatch = useDispatch()
  const data = useSelector((state) => state.InventoryReducer.inventoryList)
  const pagination = useSelector((state) => state.InventoryReducer.inventoryListPagination)
  console.log('InventoryReducer', pagination)
  useEffect(() => {
    dispatch(inventoryList({ offset, limit }))
  }, [])

  useEffect(() => {
    setDataToExport([])
    if (pagination?.currentPage === 1) {
      setOffset(0)
      setLimit(10)
    }
  }, [data])




  const dataSource = data.map((e) => {
    return {
      key: "1",
      name: e.fullName ? e.fullName : "N/A",
      email: e.email ? e.email : "N/A",
      number: e.countryCode + " " + e.phone,
      language: e.language ? e.language : "N/A",
      // items: "2",
      //type: "1",
      record: e.record,
      images: e.images,
      inventory: e.inventory,
    }

  })


  // const dataSource = [
  //   {
  //     key: "1",
  //     name: "John Dee",
  //     email: "john@yopmail.com ",
  //     number: "+91 8345734857",
  //     language: "English",
  //     items: "2",
  //     type: "1",
  //     record: "15",
  //     images: "6",
  //     inventory: "1",
  //   },
  //   {
  //     key: "1",
  //     name: "John Dee",
  //     email: "john@yopmail.com ",
  //     number: "+91 8345734857",
  //     language: "English",
  //     items: "2",
  //     type: "1",
  //     record: "15",
  //     images: "6",
  //     inventory: "1",
  //   },
  //   {
  //     key: "1",
  //     name: "John Dee",
  //     email: "john@yopmail.com ",
  //     number: "+91 8345734857",
  //     language: "English",
  //     items: "2",
  //     type: "1",
  //     record: "15",
  //     images: "6",
  //     inventory: "1",
  //   },
  //   {
  //     key: "1",
  //     name: "John Dee",
  //     email: "john@yopmail.com ",
  //     number: "+91 8345734857",
  //     language: "English",
  //     items: "2",
  //     type: "1",
  //     record: "15",
  //     images: "6",
  //     inventory: "1",
  //   },
  //   {
  //     key: "1",
  //     name: "John Dee",
  //     email: "john@yopmail.com ",
  //     number: "+91 8345734857",
  //     language: "English",
  //     items: "2",
  //     type: "1",
  //     record: "15",
  //     images: "6",
  //     inventory: "1",
  //   },
  // ];
  let [dataToExport, setDataToExport] = useState([])

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => offset + index + 1,
    },

    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },

    {
      title: "Email Address ",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },

    {
      title: "Phone Number ",
      dataIndex: "number",
      key: "number",
      sorter: true,
    },

    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      sorter: false,
    },

    // {
    //   title: "Items",
    //   dataIndex: "items",
    //   key: "items",
    //   sorter: true,
    // },

    // {
    //   title: "Type",
    //   dataIndex: "type",
    //   key: "type",
    //   sorter: true,
    // },

    {
      title: "Record",
      dataIndex: "record",
      key: "record",
      sorter: false,
    },

    {
      title: "Images",
      dataIndex: "images",
      key: "images",
      sorter: false,
    },

    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
      sorter: false,
    },

    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div>
            <div className="check-box add-kra">
              <Checkbox
                onChange={(e) => {
                  e.target.checked ? setDataToExport((pre) => [...pre, item]) : setDataToExport(pre => pre.filter(e => e.email != item.email))
                }}
                checked={dataToExport.find((e) => e.email === item.email ? true : false)}
              ></Checkbox>
            </div>
          </div>
        );
      },
    },
  ];

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (csvData, fileName) => {
    const csvRecords = []
    csvData.forEach(e => {
      csvRecords.push({
        "Full Name": e.name,
        "Email Address": e.email,
        "Phone Number": e.number,
        Language: e.language,
        Record: e.record,
        Images: e.images,
        Inventory: e.inventory
      })
    })
    const ws = XLSX.utils.json_to_sheet(csvRecords, { header: ['Full Name', 'Email Address', 'Phone Number', 'Language', 'Record', 'Images', 'Inventory'] });
    //const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

    const colWidths = [];
    ws['!cols'] = [];

    // Iterate over each row
    XLSX.utils.sheet_to_json(ws, { header: 1 }).forEach(row => {
      // Iterate over each cell in the row
      row.forEach((cell, colIndex) => {
        // Check if the column width needs to be adjusted
        colWidths[colIndex] = Math.max(colWidths[colIndex] || 0, cell.toString().length);
      });
    });

    // Set the column widths in the worksheet
    colWidths.forEach((width, colIndex) => {
      ws['!cols'][colIndex] = { width: width + 2 }; // You can adjust the constant value as needed
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }




  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isModalVisibleBlock, setIsModalVisibleBlock] = useState(false);
  const showModalBlock = () => {
    setIsModalVisibleBlock(true);
  };

  const handleOkBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const handleCancelBlock = () => {
    setIsModalVisibleBlock(false);
  };

  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = 'Super Note';
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["usermanagement"]}
        pathName={[
          <img src={images.home} />,
          appconstant.UserManagement,
        ]}
      />

      <div
        className="site-layout-background"
        style={{ minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.UserManagement}
            </h6>
          </div>
          <div className="system-line">
            <button className="button-list" onClick={() => {
              console.log('dataToExport', dataToExport)
              if (dataToExport.length == 0) toast.error('Please select some records for export.')
              else exportToCSV(dataToExport, Date.now())
            }}>
              {appconstant.Export}
            </button>

            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={search}
              onChange={(e) => {
                const value = (e.target.value).trimStart()
                setSearch(value)
                dispatch(inventoryList({ search: value }))
              }}
            />
          </div>
          <div className="tableContainer">
            <Table
              dataSource={dataSource}
              columns={columns}
              showSorterTooltip={false}
              onChange={(aaa, aa, sort) => {
                const { field, order } = sort
                console.log('field', field)
                let sortData = null
                switch (field) {
                  case 'name':
                    sortData = "fullName"
                    break;
                  case 'email':
                    sortData = "email"
                    break;
                  case 'number':
                    sortData = "phone"
                    break;
                  default:
                    break;
                }
                console.log('search', search)
                // if (field) sortData = "fullName"
                let orderStatus = null
                if (order === "ascend") orderStatus = 1
                else if (order === "descend") orderStatus = -1
                dispatch(inventoryList({ sort: sortData, order: orderStatus, offset: search ? 0 : offset, limit: search ? 10 : limit, search }))

              }}

            />
          </div>
          <div className="bott-pagination">
            <Pagination current={pagination?.currentPage} defaultCurrent={1} total={pagination?.totalCount}
              showSizeChanger={false}
              onChange={(pageNumber) => {
                const pageSize = 10
                setOffset(((pageNumber - 1) * pageSize))
                setLimit(pageSize)
                dispatch(inventoryList({ offset: ((pageNumber - 1) * pageSize), limit: pageSize }))
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default InventoryManagement;
