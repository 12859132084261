import './App.css';
import '../src/css/style.css'
import '../src/css/responsive.css'
import 'antd/dist/antd.css'
import RoutesNew from './Route/Routes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { dispatchFn, naviagetFn } from './themes/UTILS/dispatchNavigate';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";



function App() {
  const dispatch = useDispatch();
  const naivigate = useNavigate();

  //getNavigate(naivigate)

  dispatchFn(dispatch);
  naviagetFn(naivigate);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={false}
        rtl={false}
        limit={1}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <RoutesNew />
    </>
  );
}

export default App;
