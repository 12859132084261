import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const instialState = {
  isLoading: false,
  data: null,
  token: null,
  isVerfy: false,
  linkValid: null,
  resetPasswordLoading: false,
  resetPasswordLink: null

};
export const AuthReducer = (state = instialState, action) => {
  switch (action.type) {
    //login
    case reduxConstant.LOGIN_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuth: action.payload.success,
      };
    case reduxConstant.LOGIN_FAIL:
      return {
        ...state,
        token: null,
        isAuth: false,
        isLoading: false,
      };

    //forgot pasword

    case reduxConstant.FORGOTPASSWORD_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.FORGOTPASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.FORGOTPASSWORD_FAIL:
      return {
        ...state,
        token: null,
        isLoading: false,
      };

    //check link

    case reduxConstant.CHECK_LINK_LOAD:
      return {
        ...state,
        isLoading: true,
        resetPasswordLoading: true,
        resetPasswordLink: null
      };
    case reduxConstant.CHECK_LINK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        resetPasswordLoading: false,
        resetPasswordLink: action?.payload?.data?.link,
      };
    case reduxConstant.CHECK_LINK_FAIL:
      console.log("Called");
      return {
        ...state,
        token: null,
        isLoading: false,
        resetPasswordLoading: false,
        resetPasswordLink: false,
      };

    //reset password

    case reduxConstant.RESET_PASSWORD_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.RESET_PASSWORD_FAIL:
      return {
        ...state,
        token: null,
        isLoading: false,
      };

    //change password

    case reduxConstant.CHANGE_PASSWORD_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        token: null,
        isLoading: false,
      };


    case reduxConstant.LOGOUT_LOAD:
      return {
        ...state,
        isLoading: true,
      };
    case reduxConstant.LOGOUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case reduxConstant.LOGOUT_FAIL:
      return {
        ...state,
        token: null,
        isLoading: false,
      };
    default:
      return state;
  }
};
