//** THEMES**/

import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

//** Login **/

export const LoginAction = (data) => ({ type: reduxConstant.LOGIN_LOAD, data });

// ** Forgot Password **/

export const ForgotpasswordAction = (data, navigate) => ({
  type: reduxConstant.FORGOTPASSWORD_LOAD,
  data,
  navigate,
});

// //** rest password **/

export const ResetPasswordAction = (data, id) => ({
  type: reduxConstant.RESET_PASSWORD_LOAD,
  data,
  id,
});

// //** Check Link valid  **/

export const checkLinkAction = (data) => ({
  type: reduxConstant.CHECK_LINK_LOAD,
  data,
});

// //** change password **/

export const ChangePasswordAction = (oldPassword, newPassword, navigate) => ({
  type: reduxConstant.CHANGE_PASSWORD_LOAD,
  oldPassword,
  newPassword,
  navigate,
});

export const logout = (navigate) => ({
  type: reduxConstant.LOGOUT_LOAD,
  navigate
});

// //** Reset Store **/

export const resetStore = () => ({
  type: reduxConstant.RESET_STORE,
});

//Logout
export const LogoutAction = () => ({
  type: reduxConstant.LOGOUT_LOAD,
});
//Loader
export const LoaderAction = () => ({
  type: reduxConstant.ON_LOAD,
});
