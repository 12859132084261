import { APIMethod } from "./ApiMethods";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
}

const headers1 = {
  "Content-Type": "multipart/form-data; boundary=12345",
  Accept: "application/json",
}




//***********************************************  AUTH ***************************************************************/

//login
export const LoginApi = (data) => {
  return APIMethod.POST("admin/login", JSON.stringify(data), headers);
};

//forgot password
export const ForgotPasswordApi = (data) => {
  let body = JSON.stringify({
    email: data,
  });
  return APIMethod.POST("admin/forgotPassword", body, headers);
};

//reset password
export const ResetPasswordApi = (data, id) => {
  let body = JSON.stringify({
    token: data.token,
    password: data.password,
  });
  return APIMethod.POST("admin/resetPassword", body, headers);
};

//check link forgot password
export const checkLinkApi = (data) => {
  let body = JSON.stringify({
    token: data.token,
  });
  return APIMethod.POST("admin/resetPasswordLink", body, headers);
};

//change password
export const changePasswordApi = (oldPassword, newPassword) => {
  let body = JSON.stringify({
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
  return APIMethod.POST("admin/changePassword", body, headers);
};


//logout 

export const logout = () => {
  return APIMethod.GET("admin/logout", headers);
};

//***********************************************  Inventory ***************************************************************/


export const InventoryApi = (data) => {
  return APIMethod.POST("admin/userList", data, headers);
};


//***********************************************  Favourite ***************************************************************/


export const AddFavouriteApi = (data) => {
  return APIMethod.POST("admin/addCategory", data, headers);
};


export const CategoryListApi = (data) => {
  return APIMethod.POST("admin/getCategoryList", data, headers);
};


export const CategoryDisable = (data) => {
  return APIMethod.POST("admin/disableCategory", data, headers);
};


export const CategoryEdit = (data) => {
  return APIMethod.POST("admin/updateCategory", data, headers);
};



//***********************************************  ADS ***************************************************************/

export const AdsList = (data) => {
  return APIMethod.POST("admin/getAdsList", data, headers);
};


export const AdDelete = (data) => {
  return APIMethod.POST("admin/deleteAds", data, headers);
};


export const AdAdd = (data) => {
  return APIMethod.POST("admin/addAds", data, headers1);
};

export const AdEdit = (data) => {
  return APIMethod.POST("admin/editAds", data, headers1);
};











//***********************************************  DASHBOARD ***************************************************************/

//dashboard
export const dashboardApi = () => {
  return APIMethod.GET("admin/dashboard");
};

//***********************************************  USER ***************************************************************/

//User management
export const UsersApi = (data) => {
  return APIMethod.POST("admin/userList", JSON.stringify(data));
};
//user block
export const UserBlockApi = (data) => {
  return APIMethod.PUT(`admin/user/block/${data}`);
};
//user block
export const UserPassResetApi = (data) => {
  return APIMethod.PUT(`admin/user/reset/${data}`);
};
//user Details
export const UserDetailApi = (data) => {
  return APIMethod.GET(`admin/user/detail/${data}`);
};
//Edit user Details
export const EditUserApi = (data) => {
  return APIMethod.PUT(`admin/user/update`, JSON.stringify(data));
};

//***********************************************  GAME ***************************************************************/

//Game Management

export const GamesApi = (data) => {
  return APIMethod.POST(`admin/game/list`, JSON.stringify(data));
};
//Game view

export const GameDetailApi = (data) => {
  return APIMethod.GET(`admin/game/detail/${data}`);
};
//***********************************************  Account Delete  ***************************************************************/

//Game Management

export const AccountDeleteList = (data) => {
  return APIMethod.POST(`admin/accountDelete/requests`, JSON.stringify(data));
};
//Game view

export const AccountDeleteAccept = (data) => {
  return APIMethod.POST(`admin/acceptReject/request`, JSON.stringify(data));
};

//***********************************************  TOURNAMENT ***************************************************************/

//Tournament management
export const TournamentApi = (data) => {
  return APIMethod.POST("admin/tournament/list", JSON.stringify(data));
};
//Tournament block
export const TournamentBlockApi = (data) => {
  return APIMethod.PUT(`admin/tournament/block/${data}`);
};
//Tournament Add
export const TournamentAddApi = (data) => {
  return APIMethod.POST(`admin/tournament/add/`, JSON.stringify(data));
};
//Tournament Details
export const TournamentDetailApi = (data) => {
  return APIMethod.GET(`admin/tournament/detail/${data}`);
};
//Edit Tournament Details
export const TournamentEditApi = (data) => {
  return APIMethod.PUT(`admin/tournament/update`, JSON.stringify(data));
};

//Matchs Tournament Details
export const TournamentMatchesDetailApi = (data) => {
  return APIMethod.POST(`admin/tournament/matches`, JSON.stringify(data));
};

//Matchs Tournament Details
export const DepositApi = (data) => {
  return APIMethod.POST(`admin/payment/list`, JSON.stringify(data));
};

//***********************************************  NOTIFICATION ***************************************************************/

//NOTIFICATION LIST
export const NotificationApi = (data) => {
  return APIMethod.POST(`admin/notification/list`, JSON.stringify(data));
};
//NOTIFICATION DELETE
export const NotificationDeleteApi = (data) => {
  return APIMethod.DELETE(`admin/notification/delete/${data}`);
};
//NOTIFICATION VIEW
export const NotificationViewApi = (data) => {
  return APIMethod.GET(`admin/notification/detail/${data}`);
};
//NOTIFICATION ADD
export const NotificationAddApi = (data) => {
  return APIMethod.POST(`admin/notification/add`, JSON.stringify(data));
};
//NOTIFICATION UPDATE
export const NotificationEditApi = (data) => {
  return APIMethod.PUT(`admin/notification/update`, JSON.stringify(data));
};
//NOTIFICATION UPDATE
export const MatchTypeListApi = () => {
  return APIMethod.GET(`admin/matchType/list`);
};

//***********************************************  RESTRICTED STATES ***************************************************************/

//RESTRICTED STATE LIST
export const RestrictedStateApi = (data) => {
  return APIMethod.POST(`admin/state-list`, JSON.stringify(data));
};
//RESTRICTED STATE DELETE
export const RestrictedStateDeleteApi = (data) => {
  return APIMethod.DELETE(`admin/state-delete/${data}`);
};
//RESTRICTED STATE ADD
export const RestrictedStateAddApi = (data) => {
  return APIMethod.POST(`admin/add-state`, JSON.stringify(data));
};
//RESTRICTED STATE UPDATE
export const RestrictedStateEditApi = (data) => {
  return APIMethod.PUT(`admin/edit-state`, JSON.stringify(data));
};
