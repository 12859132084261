import downlaod from '../images/language.svg'

const images = {


    login: require('../images/logo-login.svg').default,
    fbutton: require('../images/forgotButton.svg').default,

    download: downlaod,
    down: require('../images/language.svg').default,
    login2: require('../images/slider.svg').default,
    user6: require('../images/reset-password .svg').default,
    errow: require('../images/errow.svg').default,
    alert: require('../images/alert.svg').default,
    notes: require('../images/notes.svg').default,
    star: require('../images/star.svg').default,
    addImage: require('../images/add-image.svg').default,
    menu: require('../images/menu.svg').default,
    home: require('../images/home.svg').default,
    document: require('../images/document.svg').default,
    advertising: require('../images/advertising.svg').default,
    settings: require('../images/settings.svg').default,
    logout: require('../images/logout.svg').default,
    starGold: require('../images/star-gold.svg').default,
    settingGold: require('../images/settings-gold.svg').default,
    documentGold: require('../images/document-gold.svg').default,
    advertisingGold: require('../images/advertising-gold.svg').default,
    cross: require('../images/icons8-multiply-30.svg').default,
    preview: require('../images/preview.jpg').default

}

export default images;