import React, { useState, Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Changepassword from "../components/changepassword";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import MainLayout from "../layout/mainlayout";
import AddFavorite from "../components/AddFavorite";
import Setting from "../components/Setting";
import InventoryManagement from "../components/inventorymanagement";
import AdsManagement from "../components/adsmanagement";
import ChangeLanguage from "../components/changelanguage";
import ResetPassword from "../components/ResetPassword";
import PrivateRoute, { PublicRoute } from "./privateRoute";
const RoutesNew = () => {
  return (
    <div>
        <Routes>
        <Route path="/" element={localStorage.token ? <Navigate to={'/usermanagement'} /> : <Navigate to={'/login'} />} />
        <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
          <Route path="/forgotpassword"element={<PublicRoute><ForgotPassword /></PublicRoute>} />
          <Route path="/resetpassword" element={<ResetPassword />} />

          <Route path='/' element={
          <PrivateRoute><MainLayout /></PrivateRoute>
        }>
                 <Route path="/" element={
            <PrivateRoute>
              <Navigate to={'/usermanagement'} />
            </PrivateRoute>} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/adsmanagement" element={<AdsManagement />} />
            <Route path="/changepassword" element={<Changepassword />} />

            <Route path="/addfavorite" element={<AddFavorite />} />
            <Route
              path="/usermanagement"
              element={
                <PrivateRoute>
              <InventoryManagement />
              </PrivateRoute>
              }
            />
            <Route path="/changelanguage" element={<ChangeLanguage />} />
          </Route>
        </Routes>
    </div>
  );
};
export default RoutesNew;
