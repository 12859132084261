import React from "react";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import images from "../themes/appImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUnlock,
  faSignOutAlt,
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";

import { Button, Tooltip } from 'antd';

import { logout } from '../../src/Store/Action/Auth'

import { useDispatch } from "react-redux";


const { Header, Content, Footer, Sider } = Layout;

const Sidebar = (props) => {
  const dispatch = useDispatch()
  let navigate = useNavigate();
  let location = useLocation();

  const onMenuClick = (e) => {
    navigate(e.key);
    // history.push(e.key);
  };

  const disableModel = (record) => {
    Modal.confirm({
      icon: <img className="moale-image" src={images.alert} />,
      content: (
        <div className="content-model">
          <p> Are you sure,</p> <p>You want to logout? </p>
        </div>
      ),
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        console.log('okkkkkkkkk')
        dispatch(logout(navigate))

        // isInternetConnected(history) &&
        //     dispatch(enableDisableAction(record._id, !record.isBlock, history, search, offset, limit, sortBy, order, status))
      },
      onCancel() { },
      wrapClassName: "new-button",
    });
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/setting":
      activeKey = "/setting";
      break;
    case "/changepassword":
      activeKey = "/setting";
      break;
    case "/changelanguage":
      activeKey = "/setting";
      break;

    case "/addfavorite":
      activeKey = "/addfavorite";
      break;
    case "/usermanagement":
      activeKey = "/usermanagement";
      break;

    case "/adsmanagement":
      activeKey = "/adsmanagement";
      break;
    default:
      activeKey = "/usermanagement";

  }

  console.log('activeKey', activeKey)

  return (
    <Sider
      // breakpoint="lg"
      className={props.isVisible ? "sider_left new closedd" : "sider_left new"}
      collapsedWidth="0"
      width={props.isVisible ? "108" : "250"}
      onBreakpoint={(broken) => {
        console.log('breakPoint', broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log('collapsed', collapsed, type);
      }}
    >
      <div className="logo">
        <Link to="/usermanagement">
          <img src={images.login2} />
        </Link>
      </div>
      {/* <div className="admin-text">
        <h2>Super Note</h2>
      </div> */}

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => onMenuClick(e)}
      >

        <Menu.Item key="/usermanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "User Managment" : null}
          >
            {activeKey === "/usermanagement" ? <img src={images.documentGold} alt="star-gold" /> : <img src={images.document} />}
            <span className="line_dash">User Management</span>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/addfavorite">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Add Favorite" : null}
          >
            {activeKey === "/addfavorite" ? <img src={images.starGold} alt="star-gold" /> : <img src={images.star} />}
            <span className="line_dash">Add Favorite</span>
          </Tooltip>
        </Menu.Item>



        <Menu.Item key="/adsmanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Ads Management" : null}
          >
            {activeKey === "/adsmanagement" ? <img src={images.advertisingGold} alt="star-gold" /> : <img src={images.advertising} />}
            <span className="line_dash">Ads Management</span>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/setting">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Settings" : null}
          >
            {activeKey === "/setting" ? <img src={images.settingGold} alt="star-gold" /> : <img src={images.settings} />}
            <span className="line_dash">Settings</span>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="#" onClick={disableModel}>
          <Tooltip
            placement="right"
            title={props.isVisible ? "Logout" : null}
          >
            <img src={images.logout} />
            <span className="line_dash"> Logout</span>
          </Tooltip>
        </Menu.Item>
      </Menu>
      <div className="angle-btn">
        <button type="submit" onClick={props.handlewClick} className=" btn ">
          <FontAwesomeIcon
            className="icon-angle-left"
            icon={props.isVisible ? faAngleRight : faAngleLeft}
          />
        </button>
      </div>
    </Sider>
  );
};
export default Sidebar;
