import images from "../themes/appImage";

const LinkExpired = (props,) => {
  return (
    <div className="auth-background" >
      <form className="auth-form link-expired-wrapper">
        <div className="login-form minHeight200" style={{maxHeight:200}}>
          <div>
            <img src={images.login} />
          </div>
            <div className="link-expired-text-wrapper">
              {props.message}
            </div>
        </div>
      </form>
    </div>
  );
};

export default LinkExpired;
