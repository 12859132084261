import React from "react";
const Button = ({
  onClick,
  name,
  type = "submit",
  className = "button-list ",
  disabel,
  style,
}) => {
  return (
    <button
      className={className}
      type={type}
      onClick={onClick}
      style={style}
      disabled={disabel}
    >
      {name}
    </button>
  );
};
export default Button;
