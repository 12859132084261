import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const initialState = {
    isLoading: false,
    data: null,
    token: null,
    isVerfy: false,
    linkValid: null,
    resetPasswordLoading: false,
    resetPasswordLink: null,
    favouriteList: [],
    favouriteListPagination: {}

};
export const FavouriteReducer = (state = initialState, action) => {
    console.log('aa', action)
    switch (action.type) {
        //add category 
        case reduxConstant.ADD_FAVOURITE_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.ADD_FAVOURITE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                favouriteList: action?.list,
                favouriteListPagination: action?.pagination
            };
        case reduxConstant.ADD_FAVOURITE_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //category list
        case reduxConstant.CATEGORY_LIST_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.CATEGORY_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                favouriteList: action?.list,
                favouriteListPagination: action?.pagination
            };
        case reduxConstant.CATEGORY_LIST_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //edit category
        case reduxConstant.CATEGORY_DISABLE_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.CATEGORY_DISABLE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                favouriteList: JSON.parse(JSON.stringify(state.favouriteList)).map(i => {
                    if ((i._id).toString() == (action?.category).toString()) i.disable = !i.disable
                    return i;
                })

            };
        case reduxConstant.CATEGORY_DISABLE_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        case reduxConstant.CATEGORY_EDIT_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.CATEGORY_EDIT_SUCCESS:
            return {
                ...state,
                isLoading: false
            };
        case reduxConstant.CATEGORY_EDIT_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        default:
            return state;
    }
};
