import React from 'react'

const Textfield = props => {

  const {
    type,
    className,
    placeholder,
    disabled,
    value,
    onChange,
    defaultValue,
    onKeyPress,
    error,
    touched,
    onBlur,
    accept,
    src,
    title,
    onClick,
    style,
    ref,
    maxLength,
    name,
    autocomplete
  } = props

  return (
    <div className='input_fields'>
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        className={className}
        disabled={disabled}
        onChange={onChange}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
        onBlur={onBlur}
        accept={accept}
        src={src}
        title={title}
        onClick={onClick}
        style={style}
        ref={ref}
        maxLength={maxLength}
        autocomplete={"off"}
      />
      <span className='login-error'>
        {error || touched ? error || touched : ''}
      </span>
    </div>
  )
}

export default Textfield
