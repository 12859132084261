import { combineReducers } from "redux";
import { AuthReducer } from "./Auth";
import { InventoryReducer } from "./inventory";
import { FavouriteReducer } from "./favourite";
import { AdsReducer } from "./ads";
import appconstant from "../../themes/appconstant";
const appReducer = combineReducers({
  AuthReducer,
  InventoryReducer,
  FavouriteReducer,
  AdsReducer
});

const rootReducer = (state, action) => {
  if (action.type === appconstant.reduxConstant.RESET_STORE) state = undefined;
  return appReducer(state, action);
};
export default rootReducer;
