import React from 'react'
import { Navigate } from 'react-router-dom'
import { DataManager } from '../helper/dataManager.js'

export const PrivateRoute = ({ children }) => {
  let tokenCheck = DataManager.getAccessToken()
  return tokenCheck ? children : <Navigate to={`/login`} />
}

export const PublicRoute = ({ children }) => {
  let tokenCheck = DataManager.getAccessToken()
  return !tokenCheck ? children : <Navigate to='/' />
}

export default PrivateRoute