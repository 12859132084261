import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import images from "../../themes/appImage";
import appconstant from "../../themes/appconstant";
import { BreadcrumbFn } from "../../common/BreadCrumbFn";

import { useNavigate } from "react-router-dom";


const Setting = (props) => {



  const navigate = useNavigate();
  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = 'Super Note';;
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <BreadcrumbFn
        path={["usermanagement"]}
        pathName={[
          <img src={images.home} />,
          appconstant.Settings,

        ]}
      />

      <div className="outer-div">
        <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
          <h6 class="text-white text-capitalize ps-3">
            {appconstant.Settings}
          </h6>
        </div>
        <div className="main-div-2 dashboard">
          <div className="datacenter user-valid-text" onClick={() => navigate("/changelanguage")} >
            <div className="image-fle">
              <div>
                <img
                  src={images.down}
                  style={{ width: "76px", Height: "76px" }}
                />
              </div>
            </div>
            <div className="user-text" >
              <p>Change Language</p>
            </div>
          </div>

          <div className="datacenter user-valid-text" onClick={() => navigate("/changepassword")}>
            <div className="image-fle">
              <div>
                <img
                  src={images.user6}
                  style={{ width: "76px", Height: "76px" }}
                />
              </div>
            </div>

            <div className="user-text">
              <p>Change Password</p>
            </div>


          </div>
        </div>

      </div>
    </div>
  );
};
export default Setting;
