import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";

import {
    onFail,
    onError,
    Loader,
    onSucces,
    onUnAuth,
} from "../../themes/UTILS/UnAuthFailFn";

import {
    AdsList,
    AdDelete,
    AdAdd,
    AdEdit
} from "../Api";

const { reduxConstant, RoutesPath } = appconstant;

function* AdsListSaga(action) {
    try {
        const data = yield call(AdsList, action.data);
        if (data.status === 0) {
            yield put({
                type: reduxConstant.ADS_LIST_SUCCESS,
                list: data.result.data.list,
                pagination: data.result.data.pagination
            });
            // toast.dismiss()
            // toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.LOGIN_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error");
        //if some thing went wrong from api side
        yield call(onError);
    }
}

function* AdsDeleteSaga(action) {
    try {
        console.log('inside', action)
        const data = yield call(AdDelete, action.data);
        if (data.status === 0) {
            yield put({
                type: reduxConstant.ADS_DELETE_SUCCESS,
                adId: action?.data?.ads,
            });
            toast.dismiss()
            toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.LOGIN_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error");
        //if some thing went wrong from api side
        yield call(onError);
    }
}

function* AdsAddSaga(action) {
    try {
        const data = yield call(AdAdd, action.data);
        if (data.status === 0) {
            toast.dismiss()
            toast.success(data.result.message, { toastId: 1 });
            yield put({
                type: reduxConstant.ADS_LIST_LOAD,
                //adId: action?.data?.ads,
            });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.LOGIN_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error");
        //if some thing went wrong from api side
        yield call(onError);
    }
}

function* AdsEditSaga(action) {
    try {
        console.log('inside', action)
        const data = yield call(AdEdit, action.data);
        if (data.status === 0) {
            yield put({
                type: reduxConstant.ADS_LIST_LOAD,
                //adId: action?.data?.ads,
            });
            toast.dismiss()
            toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.LOGIN_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error");
        //if some thing went wrong from api side
        yield call(onError);
    }
}

export default function* AdsSaga() {
    yield takeLatest(reduxConstant.ADS_LIST_LOAD, AdsListSaga);
    yield takeLatest(reduxConstant.ADS_DELETE_LOAD, AdsDeleteSaga);
    yield takeLatest(reduxConstant.ADS_ADD_LOAD, AdsAddSaga);
    yield takeLatest(reduxConstant.ADS_EDIT_LOAD, AdsEditSaga);
}

