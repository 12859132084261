import React, { useState, useEffect } from "react";
import { Layout, Menu, Modal } from "antd";
import images from "../../themes/appImage";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { ForgotpasswordAction } from "../../Store/Action/Auth";
import { forgotPasswordSchema } from "../../themes/validation";
import Button from "../../common/Button";
import { toast } from "react-toastify";

const { Header, Content, Footer, Sider } = Layout;

const ForgotPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const removeSpace = (e) => e.key === " " ? e.preventDefault() : null
  useEffect(() => {
    document.title = "Admin Panel";
    toast.dismiss()
    window.scrollTo(0, 0);
    return () => Modal.destroyAll()
  }, []);
  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => dispatch(ForgotpasswordAction(values.email, navigate)),

  })


  // const sentModal = (record) => {
  //   Modal.confirm({

  //     icon: <img className="moale-image" src={images.fbutton} />,
  //     content: (
  //       <div className="content-model">
  //         {" "}
  //         <p>Reset password link has been sent successfully to your registered email address. </p>
  //       </div>
  //     ),
  //     cancelButtonProps:{
  //     style:{
  //       display:'none'
  //     }
  //     },
  //     className:"mainModal",
  //     okText: "Ok",
  //     centered: true,
  //     onOk() {
  //      navigate("/login")
  //     },
  //     wrapClassName: "new-button",
  //   });
  // };

  return (
    <div className="image-bg">
      <form className="auth-form" onSubmit={formik.handleSubmit}>
        <div className="page-header">
          <div className="main-div">
            <div className="login-form mt-top minHeight300">
              <div className="back">
                <Link to="/login">
                  <button type="submit" className=" btn2  ">
                    <img
                      className="icon-angle-left" src={images.errow} />


                  </button>
                </Link>
              </div>

              <h4>Forgot Password </h4>

              <div className="user forg-feld">
                <input
                  name='email'
                  className="input"
                  type="text"
                  onChange={(e) => {
                    let data = e.target.value.trim();
                    formik.setFieldValue("email", data);
                  }}
                  value={formik.values.email.trim()}
                  onBlur={formik.handleBlur}
                  placeholder="Email Address" />

              </div>
              {formik.errors.email && formik.touched.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
              <div className="login-link">
                <Button className="login-link" name={"Send"}></Button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default ForgotPassword;
