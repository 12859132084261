import React from "react";
import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

//** THIS FUNCTION IS USE TO DISPLAY THE BREADCRUMB  */

export const BreadcrumbFn = ({ path, pathName }) => {
  const totalBreadcrumbItem = () => {
    return pathName?.map((item, index) => {
      return path[index] ? (
        <Breadcrumb.Item  key={pathName[index]}>
          
          <Link to={`/${path[index]}`}>{item}</Link>
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={""}>{item}</Breadcrumb.Item>
      );
    });
  };
  return <Breadcrumb  separator=">">{totalBreadcrumbItem()}</Breadcrumb>;
};