import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const initialState = {
    isLoading: false,
    data: null,
    token: null,
    isVerfy: false,
    linkValid: null,
    resetPasswordLoading: false,
    resetPasswordLink: null,
    adsList: [],
    adsListPagination: {}

};
export const AdsReducer = (state = initialState, action) => {

    switch (action.type) {
        //ads list
        case reduxConstant.ADS_LIST_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.ADS_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adsList: action?.list,
                adsListPagination: action?.pagination
            };
        case reduxConstant.ADD_FAVOURITE_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //ads delete
        case reduxConstant.ADS_DELETE_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.ADS_DELETE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adsList: JSON.parse(JSON.stringify(state.adsList)).filter(i => (i._id).toString() != (action?.adId).toString()),

            };
        case reduxConstant.ADS_DELETE_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //add ads

        case reduxConstant.ADS_ADD_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.ADS_ADD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                //  adsList: JSON.parse(JSON.stringify(state.adsList)).filter(i => (i._id).toString() != (action?.adId).toString()),

            };
        case reduxConstant.ADS_ADD_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        //add ads

        case reduxConstant.ADS_EDIT_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.ADS_EDIT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                //  adsList: JSON.parse(JSON.stringify(state.adsList)).filter(i => (i._id).toString() != (action?.adId).toString()),

            };
        case reduxConstant.ADS_EDIT_FAIL:
            return {
                ...state,
                isLoading: false,
            };



        default:
            return state;
    }
};
