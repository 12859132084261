import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;

//** ad list **/

export const adsList = (data) => ({ type: reduxConstant.ADS_LIST_LOAD, data });


//** delete Add **/

export const deleteAdd = (data) => ({ type: reduxConstant.ADS_DELETE_LOAD, data });


//** add Add **/

export const AddAd = (data) => ({ type: reduxConstant.ADS_ADD_LOAD, data });


//** edit Add **/

export const editAd = (data) => ({ type: reduxConstant.ADS_EDIT_LOAD, data });