import React, { useState, useEffect } from "react";
import { Input, Layout, Menu } from "antd";
import images from "../../themes/appImage";
import { useFormik } from "formik";
import { BrowserRouter as Router, Link, useNavigate, useLocation } from "react-router-dom";
import Textfield from "../../common";
import LinkExpired from "../../common/LinkExpired";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction, checkLinkAction, } from '../../Store/Action/Auth'
import { resetPasswordSchema } from "../../themes/validation";
import appconstant from "../../themes/appconstant";
import Button from "../../common/Button";



const { Header, Content, Footer, Sider } = Layout;

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()

  let tokenV = location.search.split("=")[1];
  const type = location.search.split("=")[2]
  const token = tokenV.split("&")[0]

  const loading = useSelector(x => x.AuthReducer.resetPasswordLoading)
  const linkFetched = useSelector(x => x.AuthReducer.resetPasswordLink)
  const resetFromApp = useSelector(x => x.AuthReducer.resetFromApp)

  const removeSpace = (e) => e.key === " " ? e.preventDefault() : null
  document.title = !loading && !linkFetched ? "Link Expired" : "Super Note"
  console.log(linkFetched, "linkFetched", loading);
  useEffect(() => {
    dispatch(checkLinkAction({ token }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const formik = useFormik({

    initialValues: {
      password: "",
      confirmPassword: ""
    },
    onSubmit: (values) => dispatch(ResetPasswordAction({ token, password: values.password }, navigate)),
    validationSchema: resetPasswordSchema
  })
  useEffect(() => {
    document.title = 'Super Note';;
  }, []);

  return (
    <>
      {linkFetched === null ? null : linkFetched === true ?  (
          <div className='image-bg'>
            <div className='page-header'>
              <div className='main-div'>
                <form className="auth-form" onSubmit={formik.handleSubmit}>
                  <div className='login-form'>
                    <div>
                      <img src={images.login} />
                    </div>
                    <h3>{appconstant.resetPassword}</h3>

                    <Input.Password
                      className="Passwordinput"
                      name="password"
                      type='password'
                      placeholder='Password'
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        let data = e.target.value.trim();
                        formik.setFieldValue("password", data);
                      }}
                      value={formik.values.password.trim()}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div className="error">{formik.errors.password}</div>
                    ) : null}


                    <Input.Password
                      className="Passwordinput"
                      name="confirmPassword"
                      type='password'
                      placeholder='Confirm Password'
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        let data = e.target.value.trim();
                        formik.setFieldValue("confirmPassword", data);
                      }}
                      value={formik.values.confirmPassword.trim()}
                    />
                    {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                      <div className="error">{formik.errors.confirmPassword}</div>
                    ) : null}
                    <div className="login-link " style={{marginTop:10}}>
                      <Button className={"login-link"} name={"Reset"} />
                    </div>
                  
                  </div>
                </form>
              </div>
            </div>
          </div>

        ) :<LinkExpired loading={loading} message={appconstant.linkExpireMessage} />
      }
    </>
  );
};
export default ResetPassword;
