import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";

import {
    onFail,
    onError,
    Loader,
    onSucces,
    onUnAuth,
} from "../../themes/UTILS/UnAuthFailFn";

import {
    LoginApi,
    ForgotPasswordApi,
    checkLinkApi,
    ResetPasswordApi,
    changePasswordApi,
    InventoryApi
} from "../Api";

const { reduxConstant, RoutesPath } = appconstant;

function* InventoryListSaga(action) {
    try {
        const data = yield call(InventoryApi, action.data);
        if (data.status === 0) {
            yield put({
                type: reduxConstant.INVENTORY_SUCCESS,
                list: data.result.data.list,
                pagination: data.result.data.pagination
            });
            // toast.dismiss()
            // toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.LOGIN_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error");
        //if some thing went wrong from api side
        yield call(onError);
    }
}



export default function* rootAuthSaga() {
    yield takeLatest(reduxConstant.INVENTORY_LOAD, InventoryListSaga);
}
