import appconstant from "../../themes/appconstant";
const { reduxConstant } = appconstant;
const initialState = {
    isLoading: false,
    data: null,
    token: null,
    isVerfy: false,
    linkValid: null,
    resetPasswordLoading: false,
    resetPasswordLink: null,
    inventoryList: [],
    inventoryListPagination: {}

};
export const InventoryReducer = (state = initialState, action) => {
    // console.log('aa', action)
    switch (action.type) {
        //inventory list
        case reduxConstant.INVENTORY_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.INVENTORY_SUCCESS:
            return {
                ...state,
                isLoading: false,
                inventoryList: action?.list,
                inventoryListPagination: action?.pagination
            };
        case reduxConstant.INVENTORY_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        default:
            return state;
    }
};
