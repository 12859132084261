import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import appconstant from "../../themes/appconstant";
import { navigate } from "../../themes/UTILS/dispatchNavigate";

import {
    onFail,
    onError,
    Loader,
    onSucces,
    onUnAuth,
} from "../../themes/UTILS/UnAuthFailFn";

import {
    AddFavouriteApi, CategoryListApi, CategoryDisable, CategoryEdit
} from "../Api";

const { reduxConstant, RoutesPath } = appconstant;


function* AddFavouriteSaga(action) {
    try {
        console.log('action.data', action)
        const data = yield call(AddFavouriteApi, action.data);
        console.log('data', data)
        if (data.status === 0) {
            toast.success(data.result.message);
            // const categoryData = yield call(CategoryListApi, { offset: 0, limit: 10 });
            yield put({
                type: reduxConstant.CATEGORY_LIST_LOAD,
                // list: categoryData.result.data.categoryList,
                // pagination: categoryData.result.data.pagination
            });
            // toast.dismiss()
        }
        // for fail
        else {

            let obj = {
                type: reduxConstant.ADD_FAVOURITE_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error", e);
        //if some thing went wrong from api side
        yield call(onError);
    }
}


function* categoryList(action) {
    try {
        console.log('action.data', action.data)
        const data = yield call(CategoryListApi, action.data);
        console.log('data', data)
        if (data.status === 0) {
            yield put({
                type: reduxConstant.CATEGORY_LIST_SUCCESS,
                list: data.result.data.categoryList,
                pagination: data.result.data.pagination
            });
            // toast.dismiss()
            // toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.ADD_FAVOURITE_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error", e);
        //if some thing went wrong from api side
        yield call(onError);
    }
}

function* categoryDisable(action) {
    try {
        console.log('action.data', action.data)
        const data = yield call(CategoryDisable, action.data);
        if (data.status === 0) {
            yield put({
                type: reduxConstant.CATEGORY_DISABLE_SUCCESS,
                category: action.data.category,
            });
            toast.dismiss()
            toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.ADD_FAVOURITE_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error", e);
        //if some thing went wrong from api side
        yield call(onError);
    }
}


function* categoryEdit(action) {
    try {
        console.log('action.data', action.data, action.navigate)
        const { navigate } = action

        const data = yield call(CategoryEdit, action.data);
        if (data.status === 0) {
            // const categoryData = yield call(CategoryListApi, {});
            yield put({
                type: reduxConstant.CATEGORY_LIST_LOAD,
                // type: reduxConstant.CATEGORY_LIST_SUCCESS,
                // list: categoryData.result.data.categoryList,
                // pagination: categoryData.result.data.pagination
            });
            toast.dismiss()
            toast.success(data.result.message, { toastId: 1 });
        }
        // for fail
        else {
            let obj = {
                type: reduxConstant.ADD_FAVOURITE_FAIL,
                message: data?.result?.message,
            };
            yield call(onFail, obj);
        }
    } catch (e) {
        console.log("error", e);
        //if some thing went wrong from api side
        yield call(onError);
    }
}

export default function* favouriteSaga() {
    yield takeLatest(reduxConstant.ADD_FAVOURITE_LOAD, AddFavouriteSaga);
    yield takeLatest(reduxConstant.CATEGORY_LIST_LOAD, categoryList);
    yield takeLatest(reduxConstant.CATEGORY_DISABLE_LOAD, categoryDisable);
    yield takeLatest(reduxConstant.CATEGORY_EDIT_LOAD, categoryEdit);
}
